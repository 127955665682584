<cca-page-heading size="xl">Accessibility statement for Climate Change Agreement (CCA)</cca-page-heading>

<p class="govuk-body">This accessibility statement applies to the Climate Change Agreement digital service.</p>
<p class="govuk-body">
  This website is run by the Department for Energy and Net Zero (DESNZ). We want as many people as possible to be able
  to use this website. For example, that means you should be able to:
</p>
<ul class="govuk-list govuk-list--bullet">
  <li>change colours, contrast levels and fonts</li>
  <li>zoom in up to 300% without the text spilling off the screen</li>
  <li>navigate most of the website using just a keyboard</li>
  <li>navigate most of the website using speech recognition software</li>
  <li>
    listen to most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)
  </li>
</ul>
<p class="govuk-body">We have also made the website text as simple as possible to understand.</p>
<p class="govuk-body">
  <a class="govuk-link" href="https://mcmw.abilitynet.org.uk/" rel="noreferrer noopener">AbilityNet</a> has advice on
  making your device easier to use if you have a disability.
</p>

<h2 class="govuk-heading-l">How accessible this website is</h2>
<p class="govuk-body">We know some parts of this website are not fully accessible:</p>
<ul class="govuk-list govuk-list--bullet">
  <li>the heading structure is not always correct, with missing or skipped headings</li>
  <li>a clear focus indicator is not always present when tabbing to page elements</li>
  <li>some page regions are not identified with ARIA landmarks</li>
  <li>there are multiple instances of identical link text which take users to different pages</li>
  <li>the focus order is not always logical when navigating with a keyboard and/or screen reader</li>
  <li>form inputs lack a correctly associated text label or descriptive title</li>
</ul>

<h3 class="govuk-heading-m">Feedback and contact information</h3>
<p class="govuk-body">
  If you need information on this website in a different format like accessible PDF, large print, easy read, audio
  recording or braille please contact us at:
  <a class="govuk-link" href="mailto:businessenergyuse@energysecurity.gov.uk">
    businessenergyuse&#64;energysecurity.gov.uk</a
  >
</p>

<h3 class="govuk-heading-m">Reporting accessibility problems with this website</h3>
<p class="govuk-body">
  We are always looking to improve the accessibility of this website. If you find any problems not listed on this page
  or think we are not meeting accessibility requirements, contact:
  <a class="govuk-link" href="mailto:businessenergyuse@energysecurity.gov.uk">
    businessenergyuse&#64;energysecurity.gov.uk
  </a>
</p>

<h3 class="govuk-heading-m">Enforcement procedure</h3>
<p class="govuk-body">
  The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and
  Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you are not happy
  with how we respond to your complaint,
  <a href="https://www.equalityadvisoryservice.com/" class="govuk-link" rel="noreferrer noopener">
    contact the Equality Advisory and Support Service (EASS).
  </a>
</p>

<h2 class="govuk-heading-l">Technical information about this website’s accessibility</h2>
<p class="govuk-body">
  The Department for Energy and Net Zero (DESNZ) is committed to making its website accessible, in accordance with the
  Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
</p>

<h3 class="govuk-heading-m">Compliance status</h3>
<p class="govuk-body">
  This website is partially compliant with the
  <a class="govuk-link" rel="noreferrer noopener" href="https://www.w3.org/TR/WCAG22/">
    Web Content Accessibility Guidelines version 2.2
  </a>
  AA standard, due to the non-compliances and exemptions listed below.
</p>

<h2 class="govuk-heading-l">Non-accessible content</h2>
<p class="govuk-body">The content listed below is non-accessible for the following reasons.</p>

<h3 class="govuk-heading-m">Non-compliance with the accessibility regulations</h3>
<p class="govuk-body"></p>
<p class="govuk-body">
  Form input fields on the ‘Add facility details’, ‘Add target composition’, 'Add details of baseline data', and
  ‘Provide evidence’ pages lack a correctly associated text label. Incorrectly associated labels do not provide
  functionality or information about the form control to screen reader users. This fails WCAG 2.2 success criterion
  1.1.1 (non-text content).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Some page content may not be contained within a landmark element, such as breadcrumbs and the ‘Beta’ banner. This may
  impact users who navigate the page using landmarks. This fails WCAG 2.2 success criterion 1.3.1 (info and
  relationships).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  A checkbox on the ‘Add facility details’ page is grouped using a fieldset and legend with an empty legend, rather than
  having a correctly associated label. The checkbox may announce unexpectedly when navigated to with a screen reader.
  This fails WCAG 2.2 success criterion 1.3.1 (info and relationships).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  On the ‘Add facility details’ and ‘Add details of baseline data’ pages, an aria-labelledby or aria-describedby
  reference exists, but the target for the reference does not exist. ARIA labels and descriptions will not be presented
  if the element referenced does not exist in the page. This fails WCAG 2.2 success criterion 1.3.1 (info and
  relationships).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Tables within the PDF on the landing page do not contain table headers. Users of assistive technologies may have
  difficulty navigating these tables. This fails WCAG 2.2 success criterion 1.3.1 (info and relationships).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Some related form elements are not grouped using fieldset and legend. Some radio buttons or checkboxes may lack
  context without being grouped. This fails WCAG 2.2 success criterion 1.3.1 (info and relationships).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Tables are not always correctly formed, with table header cells not marked up as such. Screen reader users may have
  difficulty navigating these tables. This fails WCAG 2.2 success criterion 1.3.1 (info and relationships).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  For some input fields, such as ‘Job title’ and ‘County’, the autocomplete attribute is not used correctly. Screen
  readers do not read identified autocomplete form fields if the appropriate autocomplete attribute values are missing.
  This fails WCAG 2.2 success criterion 1.3.5 (identify input purpose).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  After a period of inactivity, the user is logged out with no options to turn off, adjust, or extend that time limit.
  This fails WCAG 2.2 success criterion 2.2.1 (timing adjustable).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  The ‘Skip to main content’ link does not always focus the user to the main page content upon interaction; instead
  navigating the user to commonly seen navigational elements, such as breadcrumbs. This fails WCAG 2.2 success criterion
  2.4.1 (bypass blocks).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  A correctly formed heading structure is not present to facilitate navigation across the site, with skipped heading
  levels and empty headings present. Skipped and empty headings will present no information and may introduce confusion
  for screen reader and keyboard users. This fails WCAG 2.2 success criterion 2.4.1 (bypass blocks).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Some page titles are repeated across multiple web pages or not reflective of the page content, which may cause
  navigation issues between tabs. This fails WCAG 2.2 success criterion 2.4.2 (page titled).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  The focus order is not always logical when navigating with a keyboard, and with desktop and mobile screen readers
  enabled. This may result in keyboard and/or screen reader users becoming disoriented. This fails WCAG 2.2 success
  criterion 2.4.3 (focus order).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Links with identical text are not distinguished by their accessible labels. This could confuse screen reader users.
  This fails WCAG 2.2 success criterion 2.4.4 (link purpose (in context)).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Empty links that contain no text are present on some ‘Check answers’ pages. The function or purpose of these links
  will not be presented to screen reader users. This fails WCAG 2.2 success criterion 2.4.4 (link purpose (in context)).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Headings within the PDF on the landing page are not nested appropriately. Screen reader users may miss content when
  navigating via headings. This fails WCAG 2.2 success criterion 2.4.6 (headings and labels).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  A clear and consistent visual focus indicator is not always present on the ‘Provide evidence’ page. This can make it
  difficult for keyboard-only users to track the elements in focus. This fails WCAG 2.2 success criterion 2.4.7 (focus
  visible).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  Throughout the site, the ‘Choose file’ inputs all have labels that do not match or start with the visually displayed
  text of “Choose file”. This may result in users of speech recognition software being unable to interact with these
  inputs. This fails WCAG 2.2 success criterion 2.5.3 (label in name).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  On the ‘Manage facilities list’ page the table header buttons include both the up and down pointing triangles within
  their accessible name. This results in the table headers being announced unexpectedly when navigated to with a screen
  reader. This fails WCAG 2.2 success criterion 4.1.2 (name, role, value).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<p class="govuk-body">
  PDFs currently do not meet accessibility standards. This includes character encoding issues, which may not be
  accessible to screen reader users. This fails WCAG 2.2 success criterion 4.1.2 (name, role, value).
</p>
<p class="govuk-body">We plan to resolve this by: 28 February 2025</p>

<h3 class="govuk-heading-m">Disproportionate burden</h3>
<p class="govuk-body">
  Dragon Naturally Speaking (speech recognition software) can only upload files when using the mouse grid feature. This
  issue is believed to be an ongoing issue with the Dragon Naturally Speaking software and further information can be
  found on the following link:
  <a class="govuk-link" rel="noreferrer noopener" href="https://github.com/alphagov/reported-bugs/issues/35">
    https://github.com/alphagov/reported-bugs/issues/35.
  </a>
</p>

<p class="govuk-body">
  As this is a third-party issue we are unable to fix this and it is therefore
  <a class="govuk-link" rel="noreferrer noopener" href="https://www.legislation.gov.uk/uksi/2018/952/regulation/4/made"
    >exempt from meeting the accessibility regulations</a
  >
  and consequently a
  <a class="govuk-link" rel="noreferrer noopener" href="https://www.legislation.gov.uk/uksi/2018/952/regulation/7/made"
    >disproportionate burden</a
  >
  within the meaning of the accessibility regulations.
</p>

<h2 class="govuk-heading-l">Preparation of this accessibility statement</h2>
<p class="govuk-body">This statement was prepared on 18/10/2024. It was last reviewed on 18/10/2024.</p>
<p class="govuk-body">This website was last tested on 17/10/2024. The test was carried out by Zoonou.</p>
<p class="govuk-body">
  Zoonou used <a class="govuk-link" rel="noreferrer noopener" href="https://www.w3.org/TR/WCAG-EM/">WCAG-EM</a> to
  define a representative selection of pages and test approach.
</p>
<div class="govuk-!-margin-bottom-9"></div>
<cca-back-to-top></cca-back-to-top>
